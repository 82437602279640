.dropdown {
  position: relative;
  min-width: 100px;
}

.dropdown .btn {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
}

.dropdown .btn span {
  flex-grow: 1;
}

.dropdown .btn i {
  font-size: 16px;
  color: #97a1a9;
}

.menu-md {
  box-sizing: border-box;
}

.menu-md {
  position: absolute;
  width: 100%;
  max-height: 200px;
  background: white;
  padding: 0;
  margin-top: 2px;
  text-align: left;
  z-index: 40;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, .2),0px 0px 2px rgba(0, 0, 0, .06);
}

.menu-md li button {
  width: 100%;
  padding: 12px 16px;
  white-space: normal;
  transition: background .2s;
  cursor: pointer;
  border: none;
  text-align: left;
  font-size: 14px;
  color: #6a7780;
  background: white;
}

.menu-md li button:hover {
  background: #f2f4f5;
}

.menu-md li:first-child {
  margin-top: 5px;
}

.menu-md li:last-child {
  margin-bottom: 5px;
}

.menu-md li button.on {
  color: #0082ff;
  font-weight: 500;
}

.menu-md li.disabled {
  cursor: default;
}
